table.mat-mdc-table {
  padding: 0;
  margin: var(--spacing-md);
  min-width: calc(100% - (var(--spacing-md) * 2));
  --border-radius: calc(var(--borderRadius));
  border-radius: var(--border-radius) !important;
  outline: 1px solid var(--ion-color-light-tint);

  thead {
    tr {
      height: 44px;
      background-color: var(--ion-color-dark);

      th {
        font-weight: bold;
        color: var(--ion-color-dark-contrast);

        &:first-of-type {
          border-top-left-radius: var(--border-radius);
        }

        &:last-of-type {
          border-top-right-radius: var(--border-radius);
        }
      }
    }
  }

  tbody {
    tr {
      height: var(--button-height);
    }
  }
}
