ion-list.table-list {
  --border-radius: var(--borderRadius);
  --border-table-body-width: 1px;
  --avatar-height: 40px;
  padding: 0;
  margin: 0 var(--spacing-md) var(--spacing-lg) var(--spacing-md);
  border-radius: var(--border-radius);

  .row {
    display: grid;
    align-items: center;
    transition: var(--animation-duration);

    ion-avatar {
      max-height: var(--avatar-height);
      max-width: var(--avatar-height);
    }

    .progress {
      ion-progress-bar {
        width: 80%;
        border-radius: var(--border-radius);
      }
    }

    &:hover {
      background-color: rgba(var(--ion-color-dark-rgb), 0.1);
    }

    >ion-text {
      padding: calc(var(--spacing-sm) / 2) var(--spacing-sm);

      ion-avatar {
        width: var(--avatar-height);
        height: var(--avatar-height);
      }
    }

    &:last-of-type {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &:not(.header) {
      :is(.ticket-value, .progress, .status) {
        align-items: center;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--ion-color-light-tint);
      }
    }

    &.header {
      font-weight: bold;
      background: var(--linear-g-bg);
      color: var(--ion-color-primary-contrast);
      padding-block: var(--spacing-sm);
    }

    &.footer {
      ion-text {
        grid-column: span 8;
      }
    }
  }
}


._isdesktop .table-list {
  height: calc(100% - var(--spacing-lg));

  div.body {
    max-height: calc(100% - 44px - var(--border-table-body-width));
    overflow: auto;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    border-bottom: 1px solid var(--ion-color-light-tint);
    border-inline: 1px solid var(--ion-color-light-tint);

    .row {
      &:not(.header) {
        >ion-text {
          height: -webkit-fill-available;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex-direction: column;
          display: flex;
          justify-content: center;
        }

        &:not(.footer) {
          >ion-text {
            min-height: var(--avatar-height);
          }
        }
      }
    }
  }
}

#purchased-numbers {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  justify-content: center;
  align-items: center;
  padding-inline: var(--spacing-md);

  li {
    background: rgba(var(--ion-color-dark-rgb), .1);
    border-radius: calc(var(--borderRadius) / 2);
    height: 30px;
    margin: calc(var(--spacing-sm) / 2);
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 5px 10px;
    min-width: 35px;
  }
}

li.winner {
  background: var(--linear-g-bg) !important;
  color: rgba(255, 255, 255, 1) !important;
  scale: 1.1 !important;
}
