ion-split-pane {
  --side-min-width: 0;
  --side-max-width: var(--menu-width);
}

ion-list {
  background: transparent !important;
}

ion-item {
  --background: transparent;
}
