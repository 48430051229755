a {
  text-decoration: none;
  color: currentColor;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

ion-button:where([fill="outline"], [fill="clear"]) ion-text,
.linear-g-color {
  background: var(--linear-g-bg);
  width: fit-content;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flex,
.flex-column {
  &.gap-sm {
    gap: var(--spacing-sm);
  }

  &.gap-md {
    gap: var(--spacing-md);
  }

  &.gap-lg {
    gap: var(--spacing-lg);
  }

  &.gap-xl {
    gap: var(--spacing-xl);
  }
}

.italic {
  font-style: italic !important;
}

.bold {
  font-weight: bold !important;
}

.underline {
  text-decoration: underline !important;
}

.small {
  font-size: .8rem !important;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
