._isdesktop {
  ion-toast {
    --width: min(500px, 95vw);
    --border-radius: calc(var(--borderRadius) / 2);
    --height: 52px;
    --box-shadow: 0 0 10px var(--ion-color-contrast);

    &.align-end {
      --start: unset;
      --end: var(--spacing-md);
    }

    &.align-start {
      --start: var(--spacing-md);
      --end: unset;
    }
  }
}
