ion-button,
button {
  min-height: var(--button-height) !important;
  --padding-start: var(--spacing-md) !important;
  --padding-end: var(--spacing-md) !important;
  --border-radius: 2em !important;
  --border-width: 1px;

  &[fill="solid"] {
    --background: var(--linear-g-bg) !important;
    --background-hover: var(--linear-g-bg) !important;
    color: var(--ion-color-dark) !important;

    ion-text,
    ion-icon {
      color: var(--ion-color-dark) !important;
    }
  }
}
