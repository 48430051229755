// mat-paginator {
//   background: transparent !important;
// }

mat-form-field {



  .mdc-line-ripple:not(:has(mat-error)),
  .mat-mdc-form-field-subscript-wrapper:not(:has(mat-error)),
  .mat-mdc-form-field-focus-overlay:not(:has(mat-error)) {
    display: none;
  }
}
