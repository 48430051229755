ion-alert {
  --max-width: min(600px, calc(100% - 2 * var(--spacing-lg))) !important;
  --min-width: min(600px, 80%);
  backdrop-filter: blur(5px);

  .alert-title {
    --ion-text-color: var(--ion-color-primary);
  }

  .alert-message {
    white-space: pre-wrap;
    max-height: unset;
  }

  &.color-primary {
    .alert-title {
      --ion-text-color: var(--ion-color-primary);
    }

    button.alert-button {
      color: var(--ion-color-primary);
    }
  }

  &.color-secondary {
    .alert-title {
      --ion-text-color: var(--ion-color-secondary);
    }

    button.alert-button {
      color: var(--ion-color-secondary);
    }
  }

  &.color-tertiary {
    .alert-title {
      --ion-text-color: var(--ion-color-tertiary);
    }

    button.alert-button {
      color: var(--ion-color-tertiary);
    }
  }

  &.color-success {
    .alert-title {
      --ion-text-color: var(--ion-color-success);
    }

    button.alert-button {
      color: var(--ion-color-success);
    }
  }

  &.color-warning {
    .alert-title {
      --ion-text-color: var(--ion-color-warning);
    }

    button.alert-button {
      color: var(--ion-color-warning);
    }
  }

  &.color-danger {
    .alert-title {
      --ion-text-color: var(--ion-color-danger);
    }

    button.alert-button {
      color: var(--ion-color-danger);
    }
  }

  &.color-dark {
    .alert-title {
      --ion-text-color: var(--ion-color-dark);
    }

    button.alert-button {
      color: var(--ion-color-dark);
    }
  }

  &.color-medium {
    .alert-title {
      --ion-text-color: var(--ion-color-medium);
    }

    button.alert-button {
      color: var(--ion-color-medium);
    }
  }

  &.color-light {
    .alert-title {
      --ion-text-color: var(--ion-color-light);
    }

    button.alert-button {
      color: var(--ion-color-light);
    }
  }
}
