ion-card {
  margin: var(--spacing-sm);
  transition: var(--animation-duration);
  position: relative;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 5px rgba(var(--ion-color-primary-rgb), 0.5);
  --background: unset;
  border-radius: var(--borderRadius);

  ion-card-header {
    .value {
      font-size: clamp(10px, 100%, 1.2rem);
      font-weight: bold;
    }

    .label {
      font-weight: normal;
    }
  }

  &::before {
    transition: var(--animation-duration);
    content: "";
    background: var(--linear-g-bg);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.08;
  }

  &:not(.prevent-animation) {
    &:hover {
      cursor: pointer;
      translate: 0 -2px;
      box-shadow:
        0 0 5px rgba(var(--ion-color-primary-rgb), 0.5),
        0 0 8px rgba(var(--ion-color-danger-rgb), 0.5);

      ion-card-header {
        color: var(--ion-color-dark);
      }

      &::before {
        opacity: 8;
      }
    }
  }
}
