ion-modal {
  --background: rgba(27, 2, 42);
  --box-shadow: 0 0 20px rgba(27, 2, 42, 1);
  --border-radius: calc(3* var(--borderRadius));

  .ion-page {
    >*:first-child {
      height: 100%;
    }
  }

  &.blurred-bg {
    backdrop-filter: blur(10px);
  }
}
