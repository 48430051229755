.padding-sm { padding: var(--spacing-sm) !important; }
.padding-md { padding: var(--spacing-md) !important; }
.padding-lg { padding: var(--spacing-lg) !important; }
.padding-xl { padding: var(--spacing-xl) !important; }

.padding-top-sm { padding-top: var(--spacing-sm) !important; }
.padding-top-md { padding-top: var(--spacing-md) !important; }
.padding-top-lg { padding-top: var(--spacing-lg) !important; }
.padding-top-xl { padding-top: var(--spacing-xl) !important; }

.padding-right-sm, .padding-end-sm { padding-right: var(--spacing-sm) !important; }
.padding-right-md, .padding-end-md { padding-right: var(--spacing-md) !important; }
.padding-right-lg, .padding-end-lg { padding-right: var(--spacing-lg) !important; }
.padding-right-xl, .padding-end-xl { padding-right: var(--spacing-xl) !important; }

.padding-bottom-sm { padding-bottom: var(--spacing-sm) !important; }
.padding-bottom-md { padding-bottom: var(--spacing-md) !important; }
.padding-bottom-lg { padding-bottom: var(--spacing-lg) !important; }
.padding-bottom-xl { padding-bottom: var(--spacing-xl) !important; }

.padding-left-sm, .padding-start-sm { padding-left: var(--spacing-sm) !important; }
.padding-left-md, .padding-start-sm { padding-left: var(--spacing-md) !important; }
.padding-left-lg, .padding-start-sm { padding-left: var(--spacing-lg) !important; }
.padding-left-xl, .padding-start-sm { padding-left: var(--spacing-xl) !important; }

.padding-inline-sm, .padding-horizontal-sm { padding-inline: var(--spacing-sm) !important; }
.padding-inline-md, .padding-horizontal-md { padding-inline: var(--spacing-md) !important; }
.padding-inline-lg, .padding-horizontal-lg { padding-inline: var(--spacing-lg) !important; }
.padding-inline-xl, .padding-horizontal-xl { padding-inline: var(--spacing-xl) !important; }

.padding-block-sm, .padding-vertical-sm { padding-block: var(--spacing-sm) !important; }
.padding-block-md, .padding-vertical-md { padding-block: var(--spacing-md) !important; }
.padding-block-lg, .padding-vertical-lg { padding-block: var(--spacing-lg) !important; }
.padding-block-xl, .padding-vertical-xl { padding-block: var(--spacing-xl) !important; }

.margin-auto { margin: auto !important; }
.margin-top-auto { margin-top: auto !important; }
.margin-right-auto, .margin-end-auto { margin-right: auto !important; }
.margin-bottom-auto { margin-bottom: auto !important; }
.margin-left-auto, .margin-start-auto { margin-left: auto !important; }
.margin-inline-auto, .margin-horizontal-auto { margin-inline: auto !important; }
.margin-block-auto, .margin-vertical-auto { margin-block: auto !important; }

.margin-sm { margin: var(--spacing-sm) !important; }
.margin-md { margin: var(--spacing-md) !important; }
.margin-lg { margin: var(--spacing-lg) !important; }
.margin-xl { margin: var(--spacing-xl) !important; }

.margin-top-sm { margin-top: var(--spacing-sm) !important; }
.margin-top-md { margin-top: var(--spacing-md) !important; }
.margin-top-lg { margin-top: var(--spacing-lg) !important; }
.margin-top-xl { margin-top: var(--spacing-xl) !important; }

.margin-right-sm, .margin-end-sm { margin-right: var(--spacing-sm) !important; }
.margin-right-md, .margin-end-sm { margin-right: var(--spacing-md) !important; }
.margin-right-lg, .margin-end-sm { margin-right: var(--spacing-lg) !important; }
.margin-right-xl, .margin-end-sm { margin-right: var(--spacing-xl) !important; }

.margin-bottom-sm { margin-bottom: var(--spacing-sm) !important; }
.margin-bottom-md { margin-bottom: var(--spacing-md) !important; }
.margin-bottom-lg { margin-bottom: var(--spacing-lg) !important; }
.margin-bottom-xl { margin-bottom: var(--spacing-xl) !important; }

.margin-left-sm, .margin-start-sm { margin-left: var(--spacing-sm) !important; }
.margin-left-md, .margin-start-md { margin-left: var(--spacing-md) !important; }
.margin-left-lg, .margin-start-lg { margin-left: var(--spacing-lg) !important; }
.margin-left-xl, .margin-start-xl { margin-left: var(--spacing-xl) !important; }

.margin-inline-sm, .margin-horizontal-sm { margin-inline: var(--spacing-sm) !important; }
.margin-inline-md, .margin-horizontal-md { margin-inline: var(--spacing-md) !important; }
.margin-inline-lg, .margin-horizontal-lg { margin-inline: var(--spacing-lg) !important; }
.margin-inline-xl, .margin-horizontal-xl { margin-inline: var(--spacing-xl) !important; }

.margin-block-sm, .margin-vertical-sm { margin-block: var(--spacing-sm) !important; }
.margin-block-md, .margin-vertical-md { margin-block: var(--spacing-md) !important; }
.margin-block-lg, .margin-vertical-lg { margin-block: var(--spacing-lg) !important; }
.margin-block-xl, .margin-vertical-xl { margin-block: var(--spacing-xl) !important; }

.border-radius-sm { border-radius: calc(var(--borderRadius) / 2) !important; }
.border-radius-md { border-radius: var(--borderRadius) !important; }
.border-radius-lg { border-radius: calc(var(--borderRadius) * 2) !important; }
.border-radius-xl { border-radius: calc(var(--borderRadius) * 3) !important; }

.border-top-radius-sm {
  border-top-left-radius: calc(var(--borderRadius) / 2) !important;
  border-top-right-radius: calc(var(--borderRadius) / 2) !important;
}

.border-top-radius-md {
  border-top-left-radius: var(--borderRadius) !important;
  border-top-right-radius: var(--borderRadius) !important;
}

.border-top-radius-lg {
  border-top-left-radius: calc(var(--borderRadius) * 2) !important;
  border-top-right-radius: calc(var(--borderRadius) * 2) !important;
}

.border-top-radius-xl {
  border-top-left-radius: calc(var(--borderRadius) * 3) !important;
  border-top-right-radius: calc(var(--borderRadius) * 3) !important;
}

.border-right-radius-sm {
  border-top-right-radius: calc(var(--borderRadius) / 2) !important;
  border-bottom-right-radius: calc(var(--borderRadius) / 2) !important;
}

.border-right-radius-md {
  border-top-right-radius: var(--borderRadius) !important;
  border-bottom-right-radius: var(--borderRadius) !important;
}

.border-right-radius-lg {
  border-top-right-radius: calc(var(--borderRadius) * 2) !important;
  border-bottom-right-radius: calc(var(--borderRadius) * 2) !important;
}

.border-right-radius-xl {
  border-top-right-radius: calc(var(--borderRadius) * 3) !important;
  border-bottom-right-radius: calc(var(--borderRadius) * 3) !important;
}

.border-bottom-radius-sm {
  border-bottom-left-radius: calc(var(--borderRadius) / 2) !important;
  border-bottom-right-radius: calc(var(--borderRadius) / 2) !important;
}

.border-bottom-radius-md {
  border-bottom-left-radius: var(--borderRadius) !important;
  border-bottom-right-radius: var(--borderRadius) !important;
}

.border-bottom-radius-lg {
  border-bottom-left-radius: calc(var(--borderRadius) * 2) !important;
  border-bottom-right-radius: calc(var(--borderRadius) * 2) !important;
}

.border-bottom-radius-xl {
  border-bottom-left-radius: calc(var(--borderRadius) * 3) !important;
  border-bottom-right-radius: calc(var(--borderRadius) * 3) !important;
}

.border-left-radius-sm {
  border-top-left-radius: calc(var(--borderRadius) / 2) !important;
  border-bottom-left-radius: calc(var(--borderRadius) / 2) !important;
}

.border-left-radius-md {
  border-top-left-radius: var(--borderRadius) !important;
  border-bottom-left-radius: var(--borderRadius) !important;
}

.border-left-radius-lg {
  border-top-left-radius: calc(var(--borderRadius) * 2) !important;
  border-bottom-left-radius: calc(var(--borderRadius) * 2) !important;
}

.border-left-radius-xl {
  border-top-left-radius: calc(var(--borderRadius) * 3) !important;
  border-bottom-left-radius: calc(var(--borderRadius) * 3) !important;
}

.no-padding { padding: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.no-margin { margin: 0 !important; }
.no-margin-top { margin-top: 0 !important; }
.no-margin-right { margin-right: 0 !important; }
.no-margin-bottom { margin-bottom: 0 !important; }
.no-margin-left { margin-left: 0 !important; }
.no-border-radius { border-radius: 0 !important; }
