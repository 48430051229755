*:has(> mat-form-field):not(.no-padding-top) {
  padding-top: 3em !important;
}

mat-form-field {
  --form-field-radius: 2em;

  .mdc-notched-outline {
    opacity: 0;
  }

  .mat-mdc-text-field-wrapper {
    background: rgba(var(--ion-color-dark-rgb), 0.08);
    border-radius: var(--form-field-radius);
  }

  .mat-mdc-form-field-infix {
    --mat-form-field-container-vertical-padding: 0 !important;
    display: grid;

    &:has(textarea) {
      min-height: 75px;
    }

    &:has(ion-button[slot="end"]) {
      grid-template-columns: 1fr auto;

      ion-button {
        margin-block: var(--spacing-md);

        &.margin-sm {
          margin-block: var(--spacing-sm);
        }

        &.margin-lg {
          margin-block: var(--spacing-lg);
        }

        &.margin-xl {
          margin-block: var(--spacing-xl);
        }
      }
    }
  }

  label {
    position: absolute;
    bottom: calc(100% + var(--spacing-sm));
    font-weight: 700;
    font-size: 0.8em;
  }

  mat-chip-grid {
    padding: var(--spacing-md);

    .mdc-evolution-chip-set__chips {
      display: flex;
      gap: var(--spacing-sm);
    }

    mat-chip-row {
      margin: auto 0 !important;
      --mdc-chip-elevated-container-color: var(--ion-color-primary) !important;
    }
  }

  :has(textarea) {
    padding-bottom: var(--spacing-md);

    textarea {
      margin-block: var(--spacing-md) !important;
    }
  }

  &.skeleton {
    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    ion-skeleton-text {
      height: 100% !important;
      margin: 0;
      border-radius: var(--form-field-radius);
      position: absolute;

      &:not(.in-label) {
        width: 100%;
        height: 1em;
      }
    }

    label {
      left: 1rem;
      color: rgba(var(--ion-color-dark-rgb), 0.5) !important;
    }

    .mdc-floating-label {
      min-width: 100px;
    }

    input,
    textarea {
      display: none !important;
    }
  }

  &.override-disabled-cursor {
    .mdc-text-field--disabled {
      pointer-events: all !important;

      .mdc-notched-outline {
        pointer-events: all !important;
      }
    }
  }
}
