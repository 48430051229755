ion-segment {
  width: fit-content;
  max-width: calc(100% - 2 * var(--spacing-sm) - var(--spacing-sm));
  grid-auto-columns: auto;
  background: var(--ion-color-light);
  margin: var(--spacing-sm);
  border-radius: 100px;
  padding-inline: calc(var(--spacing-sm) / 2);

  ion-segment-button {
    width: fit-content;
    --color-checked: var(--ion-color-dark);
    --background-hover: transparent;

    &:not(.segment-button-checked):hover {
      ion-label {
        color: var(--ion-color-dark);
      }
    }

    ion-label {
      margin-top: 0;
      margin-bottom: 0;
    }

    &::part(indicator-background) {
      height: 100%;
      border-radius: 100px;
      height: calc(100% - calc(var(--spacing-sm) / 2));
      width: calc(100% - calc(var(--spacing-sm) / 2));
      // background: var(--ion-color-dark);
      background: var(--linear-g-bg);
    }

    &::part(indicator) {
      position: absolute;
      top: calc(var(--spacing-sm) / 2);
    }
  }
}
