.absolute {
  position: absolute !important;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.align-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.space-between {
  justify-content: space-between !important;
}

.display-none {
  display: none !important;
}

.flex,
.flex-column {
  &.gap-sm {
    gap: var(--spacing-sm);
  }

  &.gap-md {
    gap: var(--spacing-md);
  }

  &.gap-lg {
    gap: var(--spacing-lg);
  }

  &.gap-xl {
    gap: var(--spacing-xl);
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.fit-content {
  width: fit-content;
  height: fit-content;
}
