@import "@ionic/angular/css/core.css";
// @import "@ionic/angular/css/normalize.css";
// @import "@ionic/angular/css/structure.css";
// @import "@ionic/angular/css/typography.css";
// @import "@ionic/angular/css/display.css";
// @import "@ionic/angular/css/padding.css";
// @import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
// @import "@ionic/angular/css/text-transformation.css";
// @import "@ionic/angular/css/flex-utils.css";

@import "./alert.scss";
@import "./button.scss";
@import "./cards.scss";
@import "./global-variables.scss";
@import "./mat-forms.scss";
@import "./mat-reset.scss";
@import "./mat-table.scss";
@import "./reset-ionic.scss";
@import "./segment.scss";
@import "./spacing.scss";
@import "./text.scss";
@import "./toast.scss";
@import "./table-list.scss";
@import "./display.scss";
@import "./overlay.scss";
@import "./paginator.scss";

ion-toolbar {
  --border-width: 0 !important;
}

ion-menu {
  border: unset;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: var(--ion-color-light);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ion-skeleton-text {
  border-radius: calc(2 * var(--borderRadius));
}

raffles-list,
bookies-list {
  position: absolute;
  left: 0;
  width: 100%;
}

body.transparent {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%-webkit-fill-available;
  overflow: hidden;
  // background: linear-gradient(-87.5deg, rgb(5, 37, 96), rgb(8, 21, 61), rgb(0 7 47), rgb(2, 11, 29), rgb(0, 0, 0) 85%);
  // background: linear-gradient(105deg, rgb(6, 2, 2) 32.4%, rgb(137, 30, 47) 98.8%);
  // background: linear-gradient(115deg, rgb(6, 2, 2) 32.4%, rgb(27, 2, 42) 98.8%);
  // background: radial-gradient(circle at 110% 120%, rgb(27, 2, 42) 32.4%, rgb(6, 2, 2) 98.8%);
  background: var(--body-bg);

  ion-toolbar {
    --border-width: 0 !important;
    --background: transparent;
  }

  ion-menu {
    &::part(container) {
      background-color: transparent;
    }
  }

  ion-content {
    --background: transparent;
  }
}

// hide input:number arrows
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grid-two-columns {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

._isdesktop {
  .hover-pointer {
    cursor: pointer;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
    // background: rgba(var(--ion-color-primary-rgb), .1);
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  *::-webkit-scrollbar-thumb {
    // background: rgba(var(--ion-color-primary-rgb), 0.3);
    background: var(--linear-g-bg);
    border-radius: 50px;
  }
}

// remove autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white;
  // transition: background-color 5000s ease-in-out 0s;
}
